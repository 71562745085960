import { getLocale } from 'services/LocalizationService';

export const ErrorCopy = {
    params: {
        templates: {
            error: `<br><br>${getLocale('error_code')}: {ERROR_CODE}`,
        },
        url: {
            1: {
                copy: getLocale('here'),
                url: 'https://paramountplus.com/faq',
            },
            2: {
                copy: 'paramountplus.com/support',
                url: 'https://paramountplus.com/faq',
            },
            3: {
                copy: getLocale('return_home'),
                url: 'https://www.paramountplus.com/',
            },
            4: {
                copy: getLocale('faq'),
                url: 'https://www.paramountplus.com/faq',
            },
            5: {
                copy: getLocale('try_again'),
            },
            DEFAULT: {
                copy: getLocale('here'),
                url: 'https://www.paramountplus.com/faq',
            },
        },
        message: {
            1: {
                copy: getLocale('player_error_unavailable_additional_help'),
            },
            2: {
                copy: getLocale('player_error_vpn_proxy'),
            },
            3: {
                copy: getLocale('player_error_licensing'),
            },
            4: {
                copy: getLocale('player_error_trouble_playing'),
            },
            5: {
                copy: getLocale('player_error_ad_blocking'),
            },
            6: {
                copy: getLocale('player_error_internet_issues'),
            },
            100: {
                copy: getLocale('player_error_browser_not_supported'),
            },
            110: {
                copy: getLocale('player_error_unavailable_try_again'),
            },
            111: {
                copy: getLocale('player_error_sponsor_ad_blocking'),
            },
            112: {
                copy: getLocale('player_error_sponsor_private_mode'),
            },
            113: {
                copy: getLocale('player_error_sponsor_subscribe'),
            },
            114: {
                copy: getLocale('player_error_sponsor_upgrade'),
            },
            121: {
                copy: getLocale('player_error_unavailable_try_again'),
            },
            122: {
                copy: getLocale('player_error_unavailable_try_again'),
            },
            200: {
                copy: getLocale('player_error_unavailable_try_again'),
            },
            OFFLINE: {
                copy: getLocale('player_error_not_connected_to_internet'),
            },
            DEFAULT: {
                copy: getLocale('player_error_unavailable_additional_help'),
            },
        },
    },
};
